<template>
  <div class="centerbox3" style="overflow:visible">
    <ul class="columnnewlist" v-infinite-scroll="load" infinite-scroll-disabled="disabled">
      <li v-for="(item,index) in listData">
        <span class="imgboxleft">
          <img :src="item.Avatar" alt />
        </span>
        <div class="textboxcenter">
          <router-link class="titleh2" tag="h2" :to="{name:'specialColumnDetail',params:{artid:item.Artid}}">
              {{item.Article}}
          </router-link>
          <div class="text">
            <span class="left">{{item.Username}}</span>
            <span class="left">{{item.Mtime}}</span>
          </div>
          <p class="textp">{{item.Content}}</p>
          <div class="setbox">
            <span class="left">
              <i class="iconfont iconbianzu5"></i>
              {{item.Readnum}}
            </span>
            <span class="left">
              <i class="iconfont iconliaotian"></i>
              {{item.Comment}}
            </span>
            <!-- <span class="left">
              <i class="iconfont iconxingzhuang5"></i>
              {{item.Readnum}}
            </span>-->
            <span class="right" v-if="columnOwner==1">
              <i class="iconfont iconshanchu3" @click="handelDelBook(item.Artid)"></i>
            </span>
            <span class="right" v-if="columnOwner==1">
               <router-link :to="{name:'specialColumnEdit',query:{artid:item.Artid}}" tag="i" class="iconfont iconbianji2"></router-link>
            </span>
          </div>
        </div>
       <span class="imgboxright" v-if="item.Pic">
          <img :src="item.Pic" alt />
        </span>
      </li>
    </ul>
    <p v-if="loading" class="loadtextp">加载中...</p>
    <p v-if="noMore" class="loadtextp">没有更多了</p>
  </div>
</template>
<script>

import { specialPolymerRequest,specialArticleDelRequest } from "@/api/project.js";
export default {
  components: {},
  data() {
    return {
      loading: false,
      listData: [],
      totalCount: 0,
      pageCount:0,
      page: 0,
      // noMore: false
    };
  },
  created() {
    // this.getList(this.page);
  },
  computed: {
    Identify() {
      return this.$route.params.Identify || ""; //当前项目id
    },
    noMore() {
      return this.totalCount < this.pageCount*this.page
    },
    disabled() {
      return this.loading || this.noMore;
    },
    columnOwner() {
      return this.$store.state.columnOwner;
    }
  },
  methods: {
    async getList(page) {
      try {
        const result = await specialPolymerRequest({
          Identify: this.Identify,
          page:page
        });
        if (result.status == 200) {
          var _this = this;
          this.loading = false;
          this.listData = this.listData.concat(result.data.list); 
          this.totalCount = result.data.totalCount;
          this.pageCount = result.data.pageCount;
        }
      } catch (err) {}
    },
    async specialArticleDel(id) {
      try {
        const result = await specialArticleDelRequest({
          artid:id
        });
        if (result.status == 200) {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
              this.listData.some((item,index,arr)=>{
              if(id==item.Artid)  this.listData.splice(index,1)
            })
          }
          else{
            this.$message({
              type: "error",
              message: result.message
            });
          }
      } catch (err) {}
    },
    handelDelBook(id) {
        var _this=this
        this.$confirm("此操作将永久删除该文章, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            _this.specialArticleDel(id)
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除"
            });
          });
      },
    load() {
      this.page++;
      this.loading = true;
      this.getList(this.page);
    }
  }
};
</script>
<style  lang="less" scoped>
.centerbox3 {
  margin: 15px 0;
}
.columnnewlist {
  & > li {
    display: flex;
    border-bottom: 1px dashed #ccc;
    padding: 15px 0;

    .imgboxleft {
      width: 32px;
      height: 32px;
      border-radius: 40px;
      border: 1px solid #ccc;
      overflow: hidden;
      margin-right: 15px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .textboxcenter {
      flex: 1;
      .titleh2 {
        font-size: 14px;
        cursor: pointer;
      }
      .text {
        overflow: hidden;
        font-size: 13px;
        color: #888;
        line-height: 30px;
        .left {
          float: left;
          margin-right: 30px;
        }
      }
      .textp {
        font-size: 13px;
        line-height: 26px;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; //溢出隐藏
      }
      .setbox {
        margin-top: 20px;
        .left {
          color: #888;
          font-size: 14px;
          margin-right: 20px;
        }
        .right {
          color: #11adcf;
          font-size: 18px;
          float: right;
          margin-left: 15px;
          cursor: pointer;
        }
      }
    }
    .imgboxright {
      width: 205px;
      height: 123px;
      margin: 25px 0 0 25px;
      border-radius: 2px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.loadtextp{
  text-align: center;
  margin:15px 0 0;
  color:#888;
  font-size: 14px;
  line-height: 100px;
}
</style>